import React, { Component } from 'react';
import { newsletterData } from './cms-data/newsletter-data';
import Mailchimp from 'react-mailchimp-form'


export class Newsletter extends Component {
  render() {
    return (
      <section className="newsletter">
        <div className="container">
          <div className="text">
            <h2>{newsletterData.heading}</h2>
            <span>{newsletterData.sub}</span>
          </div>
        <Mailchimp
  action='https://ruecambon.us1.list-manage.com/subscribe/post?u=8a7e91767c20db6687746eed7&amp;id=41cb43dc87' 

  fields={[
    {
      name: 'EMAIL',
      placeholder: 'Email',
      type: 'email',
      required: true
    }
  ]}
  messages = {
    {
      sending: "Sending...",
      success: "Thank you for subscribing!",
      error: "An unexpected internal error has occurred.",
      empty: "You must write an e-mail.",
      duplicate: "Too many subscribe attempts for this email address",
      button: "Subscribe!"
    }
  }
  />
      </div>
      </section>
    );
  }
}
 
