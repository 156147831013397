const newsletterData = {
  heading: 'Zapisz się do newslettera',
  sub: 'Bądź na bieżąco z naszymi ofertami.',
  accept: 'Akceptuję ',
  privacyPolicy: 'politykę prywatności.',
  PPLink: '/polityka-prywatnosci',
  inputPlaceholder: 'Podaj nam swój adres e-mail',
  buttonValue: 'zapisz się',
};

export { newsletterData };