const referencesIntro = {
  heading: 'zaufali nam',
  text: 'Wszelkie starania włożone w profesjonalną obsługę i indywidualne podejście do klientów, a także szybkość w działaniu pozwoliły nam zbudować sieć współpracy z wieloma jednostkami medycznymi zarówno w Polsce jak i za granicą. Nasi klienci to przede wszystkim placówki medyczne- szpitale publiczne, wojewódzkie, liczne kliniki, szpitale prywatne, przychodnie medyczne.placówki opieki i DPS, centra fizjoterapii, kliniki medycyny estetycznej. Współpracujemy także z jednostkami państwowymi - Policja.Poczta Polska, Bank PKO oraz z wieloma hurtowniami medycznymi. Należymy do Polsko - Słowackiej izby handlowo przemysłowej. Nasze produkty zakupili klienci w Danii, Belgii, Hiszpanii, Anglii, Słowenii, Czechach i Francji. ',
  button: 'współpraca',
  link: '/współpraca',
};


const references = [
  {
    quote: "Zamówienia wykonane z należytą starannością.",
    company: "Poczta Polska",
  }, {
    quote: "Profesjonalny oraz godny zaufania Partner.",
    company: "Szpital Pediatryczny w Bielsku- Białej",
  }, {
    quote: "Polecamy jako rzetelnego dostawcę.",
    company: "Mysłowickie Centrum Zdrowia ",
  }, {
    quote: "Wiarygodny, kompetentny rzetelny i godny zaufania partner biznesowy.",
    company: "Centrum Terapii Nerwic w Mosznej",
  }, {
    quote: "Dostawy wykonane należycie i w ustalonym terminie.",
    company: "Komenda Wojewódzka Policji w Krakowie",
  }
];

export { references, referencesIntro };