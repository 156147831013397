let counterData = [
  {
    number: 506,
    text: 'Zadowolonych klientów'
  }, {
    number: 30,
    text: 'obsługiwanych szpitali w Polsce'
  }, {
    number: 15,
    text: 'zrealizowanych zamówień publicznych'
  }, {
    number: 8,
    text: 'obsługiwanych krajów'
  }, {
    number: 150,
    text: 'milionów sprzedanych rękawiczek'
  }
];

export { counterData };